import ReactQuill from "react-quill";
import React, { useEffect, useMemo, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectActiveEntry } from "journal/journalSelectors";
import { debounce } from "lodash";
import { updateEntry } from "journal/journalSlice";
import "./Editor.css"; // Import CSS for the editor component

const Editor = ({ journalContainerRef }) => {
  const dispatch = useDispatch();
  const entry = useSelector(selectActiveEntry);
  const [value, setValue] = useState(entry?.content || "");
  const quillRef = useRef(null);

  const changeHandler = (newContent) => {
    dispatch(updateEntry({ id: entry.id, content: newContent }));
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    []
  );

  const handleContentChange = (newContent) => {
    setValue(newContent);
    debouncedChangeHandler(newContent);
  };

  // =

  return (
    <div className="journal-entry-editor">
      <ReactQuill
        ref={quillRef}
        theme="snow"
        value={value}
        onChange={handleContentChange}
        scrollingContainer=".journal-entry-editor"
        placeholder="Start writing..."
        className="custom-quill"
      />
    </div>
  );
};

export default Editor;
