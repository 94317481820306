import { Button, Checkbox, Form, Input, Layout } from "antd";
import React, { useEffect, useState } from "react";
import "auth/Auth.css";
import { checkLoggedIn, login, register, requestCode } from "slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useLocation } from "react-router-dom";
import useToast from "hooks/useToast";
import Logo from "images/logo-full.jpg";
import jstz from "jstz";
import LText from "components/LText/LText";

const Signup = ({ type }) => {
  const time_zone = jstz.determine();
  const [verifyCode, setVerifyCode] = useState(false);

  const { toastError, toastInfo } = useToast();
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const from = state?.from || "/";

  useEffect(() => {
    if (user) return;

    dispatch(checkLoggedIn());
  }, []);

  const handleSubmit = (values) => {
    if (!verifyCode) {
      toastInfo("Please check your e-mail for a verification code");
      setVerifyCode(true);
      dispatch(requestCode(values.email));
    } else {
      dispatch(
        register({
          email: values.email,
          password: values.password,
          first_name: values.first_name,
          last_name: values.last_name,
          phone: values.phone,
          code: values.code,
          time_zone: time_zone.name(),
        })
      )
        .unwrap()
        .catch((error) => {
          console.log("error", error);
          toastError(error.error);
        });
    }
  };

  if (user) {
    return <Navigate to={from} replace />;
  }

  return (
    <>
      <Layout style={{ height: "100vh" }} className="Auth">
        <div className="login-form-card-container">
          <a href="/">
            <img
              src={Logo}
              style={{
                width: 150,
                marginTop: 0,
                marginBottom: 20,
              }}
            />
          </a>
          <LText inactiveColor style={{ marginBottom: 20 }}>
            {"Hi, Welcome to LumifyHub 👋"}
          </LText>
          <div style={{ marginBottom: 20 }}>
            <LText inactiveColor>Let's create you a new account</LText>
            <div style={{ marginTop: 16 }}>
              <Form
                name="basic"
                initialValues={{
                  remember: true,
                }}
                onFinish={handleSubmit}
                autoComplete="off"
                validateTrigger={"onSubmit"}
              >
                <Form.Item
                  name="first_name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your first name",
                    },
                  ]}
                >
                  <Input placeholder={"First Name"} />
                </Form.Item>

                <Form.Item name="last_name">
                  <Input placeholder={"Last Name"} />
                </Form.Item>

                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please enter an e-mail",
                      type: "email",
                    },
                  ]}
                >
                  <Input placeholder={"Email Address"} />
                </Form.Item>

                <Form.Item
                  name="phone"
                  rules={[
                    {
                      type: "phone",
                    },
                  ]}
                >
                  <Input placeholder={"Phone"} />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a password",
                    },
                    {
                      min: 8,
                      message:
                        "Password has to be a minimum of 8 characters long",
                    },
                  ]}
                >
                  <Input.Password placeholder={"Password"} />
                </Form.Item>

                {verifyCode && (
                  <Form.Item
                    name="code"
                    rules={[
                      {
                        required: verifyCode,
                        message: "Please enter in a code",
                      },
                    ]}
                  >
                    <Input placeholder={"Verification Code"} />
                  </Form.Item>
                )}

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                  >
                    {"Get Started"}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
          <p style={{ fontSize: 15, padding: 0 }}>
            <Link to={"/login"}>Already registered? Login Here</Link>
          </p>
        </div>
        <div className="auth-quote">
          <p>
            When we strive to become better than we are, everything around us
            becomes better too
            <p
              style={{
                fontSize: 10,
                marginTop: 10,
                color: "var(--inactive-color)",
              }}
            >
              - Paulo Coelho
            </p>
          </p>
        </div>
      </Layout>
      {/*<Footer />*/}
    </>
  );
};
export default Signup;
