import { Button, Dropdown, Space } from "antd";
import { createEntry } from "journal/journalSlice";
import React from "react";
import LBox from "components/LBox/LBox";
import {
  ChatBubbleBottomCenterIcon,
  PhotoIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { MODAL_NAMES, toggleModal } from "slices/modalSlice";
import { FEATURE_NAMES, VISION_ITEM_TYPES } from "constants";
import { hasReachedFeatureLimit } from "selectors/billingSelectors";

const AddCustomQuote = () => {
  const hasReachedQuoteLimit = useSelector((state) =>
    hasReachedFeatureLimit(state, {
      featureName: FEATURE_NAMES.unlimited_custom_quotes,
    })
  );

  const dispatch = useDispatch();

  return (
    <Button
      type={"primary"}
      className="add-item-button"
      onClick={() => {
        if (hasReachedQuoteLimit) {
          dispatch(
            toggleModal({
              modalName: MODAL_NAMES.PREMIUM_FEATURE,
            })
          );
          return;
        }

        dispatch(
          toggleModal({
            modalName: MODAL_NAMES.CREATE_OR_UPDATE_CUSTOM_QUOTE,
            modalProps: { isCreate: true },
          })
        );
      }}
    >
      Add Quote
    </Button>
  );
};

export default AddCustomQuote;
