import React, { useEffect, useRef } from "react";
import "./Journal.css";
import "react-quill/dist/quill.snow.css";
import { Button, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { createEntry, getEntries } from "journal/journalSlice";
import EntriesList from "journal/EntriesList";
import Editor from "journal/Editor";
import Header from "journal/Header";
import { MODAL_NAMES, toggleModal } from "slices/modalSlice";
import { hasReachedFeatureLimit } from "selectors/billingSelectors";
import { FEATURE_NAMES } from "constants";

const JournalView = () => {
  const hasReachedJournalEntryLimit = useSelector((state) =>
    hasReachedFeatureLimit(state, {
      featureName: FEATURE_NAMES.unlimited_journal_entries,
    })
  );

  const activeEntryId = useSelector((state) => state.journal.activeEntryId);
  const dispatch = useDispatch();
  const fetchingInitialData = useSelector(
    (state) => state.journal.fetchingInitialData
  );
  const journalContainerRef = useRef(null);

  useEffect(() => {
    dispatch(getEntries());
  }, [dispatch]);

  if (fetchingInitialData) {
    return <Spin />;
  }

  return (
    <div className="journal-view">
      <div className="add-item-container">
        <Button
          type={"primary"}
          className="add-item-button"
          onClick={() => {
            if (hasReachedJournalEntryLimit) {
              return dispatch(
                toggleModal({
                  modalName: MODAL_NAMES.PREMIUM_FEATURE,
                })
              );
            }

            dispatch(createEntry());
          }}
        >
          New Entry
        </Button>
      </div>
      <div className="journal-container" ref={journalContainerRef}>
        <div className="entries-list-container">
          <EntriesList />
        </div>
        <div className="journal-entry-container">
          <Header key={`${activeEntryId}-header`} />
          <Editor
            key={`${activeEntryId}-editor`}
            journalContainerRef={journalContainerRef}
          />
        </div>
      </div>
    </div>
  );
};

export default JournalView;
